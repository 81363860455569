.project-select[data-v-4bd6a64a] {
  width: 280px;
}
.toolbar[data-v-4bd6a64a] {
  display: flex;
  flex-direction: var(--4bd6a64a-flexDirection);
}
.toolbar[data-v-4bd6a64a] .input {
  margin-right: 8px;
  margin-bottom: var(--4bd6a64a-margin);
}
